<template>
  <div class="rank-category">
    <page-tree @handleNodeClick="handleTreeNodeClick" :treeProps="{
      label: 'levelName',
      children: 'children'
    }" :agencyListInfo="positionRankList">

      <template #edit="row">
        <div class="edit" v-if="row.row.level==1" @click.stop="handleRowClick(row)" v-has="'staff:station:edit'">编辑</div>
        <div class="edit" v-else @click.stop="handleRowClick(row)" v-has="'staff:rank:edit'">编辑</div>
      </template>
      <template #remove="row">
        <div class="remove" v-if="row.row.level==1" @click.stop="handleRemoveClick(row)" v-has="'staff:station:delete'">删除</div>
        <div class="remove" v-else @click.stop="handleRemoveClick(row)" v-has="'staff:station:delete'">删除</div>
      </template>
    </page-tree>

    <updateRank :rankInfo="currentEditRankInfo" v-if="updateDialogVisible" :dialogVisible='updateDialogVisible'
      @changeDialogVisible='changeupdateDialogVisible'>
    </updateRank>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import PageTree from '@/components/page-tree-copy/page-tree.vue'
import { useState } from '@/hooks'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import updateRank from '../../base-ui/update-dialog/update-dialog.vue'
import { ElMessage } from 'element-plus'
import { removeRank, removeZhiwei } from '@/service/main/staff'
export default defineComponent({
  props: {

  },
  components: {
    PageTree,
    updateRank
  },
  emits: ['handleTreeNodeClick', 'handleTreeItemChange'],
  setup(props, { emit }) {
    const store = useStore()

    const positionRankList = computed(() => store.state.staff.positionRankList)

    const initPage = () => {
      // console.log('00000000000');
      store.dispatch('staff/getStaffPositionRankListAction',
        COMPANY_ID
      )
    }
    initPage()

    emitter.on('refreshPositionRankPage', () => {
      initPage()
    })
    const handleTreeNodeClick = (item) => {
      // console.log('item=======================item');
      // console.log(item);
      emitter.emit('changeStaffRankJobLevelId', item.data.pid)
      emit('handleTreeNodeClick', item.data.lvId)
      emit('handleTreeItemChange', item)
    }

    onUnmounted(() => {
      emitter.off('refreshPositionRankPage')
    })

    const handleRowClick = item => {
      let data = item.row.data
      currentEditRankInfo.value = data
      updateDialogVisible.value = true
    }

    const handleRemoveClick = async item => {
      // console.log(item);
      let data = item.row.data
      let children = data.children
      let res = {
        code: -1
      }

      if (children) {
        res = await removeZhiwei(data.lvId)
      } else {
        // console.log('456');
        res = await removeRank(data.lvId)
      }

      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success'
        })
        initPage()
      } else {
        ElMessage({
          message: res.msg,
          type: 'warning',
        })
      }


    }

    const currentEditRankInfo = ref()
    const updateDialogVisible = ref(false)
    const changeupdateDialogVisible = flag => {
      updateDialogVisible.value = flag
    }

    return {
      currentEditRankInfo,
      changeupdateDialogVisible,
      updateDialogVisible,
      handleRowClick,
      COMPANY_ID,
      positionRankList,
      handleTreeNodeClick,
      handleRemoveClick
    }

  }
})
</script>

<style scoped lang="less">
.rank-category {
//   min-width: 260px;
//   max-width: 320px;
  height: 100%;
  background-color: white;



  &:deep(.el-tree-node__content) {

    .remove,
    .edit {
      visibility: hidden;
      color: rgb(64, 158, 255);
      font-size: 12px;
    }

    .edit {
      margin-left: 14px;
    }

    .remove {
      margin-left: 10px;
    }
  }

  &:deep(.el-tree-node__content):hover {

    .remove,
    .edit {
      visibility: visible !important;
    }
  }



  &:deep(.is-current) {
    background-color: rgb(255, 234, 234) !important;
    color: rgba(255, 141, 26, 1);
  }
}
</style>




