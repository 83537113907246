<template>
  <div class="rank-serve-list">
    <topContent :currentRnid="currentRnid"></topContent>
    <page-content
      :isBorder="false"
      :contentTableConfig="contentTableConfig"
      :isButtonStyle="true"
      :dataList="dataList"
    >
      <template #salePrice="scope"> ¥ {{ scope.row.salePrice / 100 }} </template>
      <template #serveName="scope">
        <div class="serveName">
          <div>
            <img :src="scope.row.picUrl" alt="" />
          </div>
          <div>
            {{ scope.row.serveName }}
          </div>
        </div>
      </template>
      <template #handler="scope">
        <div class="handler">
          <el-button @click="handleCalcleClick(scope.row)" size="small" plain type="danger" v-has="'staff:serve:edit'"
            >移除</el-button
          >
        </div>
      </template>
    </page-content>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, onUnmounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config/index'
import { useStore } from 'vuex'
import { getRankBindServeList } from '@/service/main/staff'
import { COMPANY_ID, UPLOAD_BASE_URL } from '@/common/constant'
import { ElMessage } from 'element-plus'
import topContent from './cpns/top-content/top-content.vue'
import breadcrumb from './cpns/breadcrumb/breadcrumb.vue'
import emitter from '@/utils/eventbus'
import { setRankBindServeList } from '@/service/main/staff'

export default defineComponent({
  props: {
    currentRank: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    PageContent,
    breadcrumb,
    topContent
  },
  setup(props, { emit }) {
    const store = useStore()

    watch(() => props.currentRank, () => {
      initPage()
      // console.log('333333333333');
    })

    const currentRnid = ref(-1)
    const dataList = ref([])

    emitter.on('refreshRankBindServeListPage', () => {
      initPage()
    })

    const handleCalcleClick = async item => {
      let obj = {
        serveId: item.serveId,
        jobLvId: currentRnid.value,
        salePrice: item.salePrice,
        promPrice: item.promPrice,
        serveTimeLength: item.serveTimeLength,
        paraText: "默认规格",
        paraId: item.paraId,
        companyId: COMPANY_ID
      }
      const res = await setRankBindServeList(obj)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        initPage()
      }
    }

    const initPage = async () => {

      if (props.currentRank&&props.currentRank.data) {
        let lvid = props.currentRank.data.lvId
        currentRnid.value = lvid
        const res = await getRankBindServeList(lvid)
        dataList.value = res.data.list
      }
    }

    initPage()

    onUnmounted(() => {
      emitter.off('refreshRankBindServeListPage')
    })

    return {
      dataList,
      contentTableConfig,
      UPLOAD_BASE_URL,
      topContent,
      currentRnid,
      handleCalcleClick
    }

  }
})
</script>

<style scoped lang="less">
.rank-serve-list {
  .serveName {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      margin-right: 10px;
    }
  }
}
</style>
