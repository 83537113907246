export const breadcrumbList = [
  {
    name: '门店管理',
    path: ''
  },
  {
    name: '职级管理',
    path: ''
  }
]
