<template>
  <div class="top-content">
    <el-button @click="handleAddOfficeClick" type="primary" v-has="'staff:station:add'">
      <el-icon>
        <plus />
      </el-icon>添加岗位
    </el-button>
    <el-button @click="handleAddRankClick" type="primary" v-has="'staff:rank:add'">
      <el-icon>
        <plus />
      </el-icon>新增职级
    </el-button>
    <add-rank-dialog v-if="addRankDialogVisible" :dialogVisible='addRankDialogVisible'
      @changeDialogVisible='changeAddRankDialogVisible'>
    </add-rank-dialog>
    <add-office-dialog v-if="addOfficeDialogVisible" :dialogVisible='addOfficeDialogVisible'
      @changeDialogVisible='changeAddOfficeDialogVisible'>
    </add-office-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import AddRankDialog from './cpns/add-rank-dialog/add-rank-dialog'
import AddOfficeDialog from './cpns/add-office-dialog/add-office-dialog'
export default defineComponent({
  props: {

  },
  components: {
    AddRankDialog,
    AddOfficeDialog
  },
  setup() {
    const addRankDialogVisible = ref(false)
    const addOfficeDialogVisible = ref(false)
    const handleAddRankClick = () => {
      addRankDialogVisible.value = true
    }
    const changeAddRankDialogVisible = (flag) => {
      addRankDialogVisible.value = flag
    }
    const handleAddOfficeClick = () => {
      addOfficeDialogVisible.value = true
    }
    const changeAddOfficeDialogVisible = (flag) => {
      addOfficeDialogVisible.value = flag
    }
    return {
      handleAddRankClick,
      changeAddRankDialogVisible,
      addRankDialogVisible,
      addOfficeDialogVisible,
      handleAddOfficeClick,
      changeAddOfficeDialogVisible
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  // padding: 18px;
  // margin: 0 16px;

  &:deep(.el-button) {
    margin-bottom: 10px;
  }
}
</style>




