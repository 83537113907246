<template>
  <div class="top-content">
    <el-button @click="handleAddStaffClick" type="primary">添加员工</el-button>
    <!-- <add-staff-dialog :jobLevel="jobLevel" :currentRnid="currentRnid" :dialogVisible="addStaffDialogVisible"
      @changeDialogVisible="changeAddStaffDialogVisible">
    </add-staff-dialog> -->
    <!-- 关联人员 -->
    <rankDistributeDialog
      v-if="addStaffDialogVisible"
      @changeDialogVisible="changeAddStaffDialogVisible"
      :dialogVisible="addStaffDialogVisible"
      :jobLevel="jobLevel"
      :currentRnid="currentRnid"
    >
    </rankDistributeDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
// import AddStaffDialog from './cpns/add-staff-dialog/add-staff-dialog'
import rankDistributeDialog from './cpns/rank-distribute-dialog/rank-distribute-dialog.vue'
import { ElMessage } from 'element-plus'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {
    currentRnid: {
      type: Number,
      default: -1
    }
  },
  components: {
    // AddStaffDialog,
    rankDistributeDialog
  },
  setup(props) {
    const addStaffDialogVisible = ref(false)
    const changeAddStaffDialogVisible = (flag) => {
      addStaffDialogVisible.value = flag
    }

    const jobLevel = ref(-1)
    emitter.on('changeStaffRankJobLevelId', pid => {
      jobLevel.value = pid
    })

    const handleAddStaffClick = () => {
      let currentRnid = props.currentRnid
      if (jobLevel.value == -1||!jobLevel.value) {
        ElMessage({
          message: '请在左侧先选择职级！',
          type: 'warning',
        })
        return
      }
      addStaffDialogVisible.value = true
    }

    return {
      addStaffDialogVisible,
      changeAddStaffDialogVisible,
      handleAddStaffClick,
      jobLevel
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  background-color: white;
  padding: 16px 22px;

}
</style>




