<template>
  <div class="staff-list-breadcrumb">
    <span v-if="currentRank && currentRank.data">
      {{ currentRank.data.levelName }}
    </span>
    <span v-else>
      <template v-if="positionRankList.length > 0">
        {{ positionRankList[0].levelName }}
      </template>
    </span>
    <span>服务列表</span>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    currentRank: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    Breadcrumb
  },
  setup() {
    const store = useStore()
    const positionRankList = computed(() => store.state.staff.positionRankList)

    return {
      breadcrumbList,
      positionRankList
    }

  }
})
</script>

<style scoped lang="less">
.staff-list-breadcrumb {
  margin-left: 26px;
  line-height: 40px;
  font-size: 14px;
  border-bottom: 1px solid var(--borderColor);

  span {
    margin-right: 4px;
  }
}
</style>




