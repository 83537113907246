export const contentTableConfig = {
  propList: [
    { prop: 'kindName', label: '分类', minWidth: '150', slotName: 'kindName' },
    { prop: 'serveName', label: '服务名称', minWidth: '150', slotName: 'serveName' },
    { prop: 'serveTimeLength', label: '服务时长', minWidth: '150',slotName:'serveTimeLength' },
    {
      prop: 'salePrice',
      label: '原价',
      minWidth: '100',
      slotName: 'salePrice'
    },
    { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
