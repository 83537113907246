<template>
  <div class="staff-list">
    <!-- <breadcrumb :currentRank="currentRank"></breadcrumb> -->
    <top-content :currentRnid="currentRnid"></top-content>
    <page-content
      :isBorder="false"
      @tableCellClick="handleTableCellClick"
      :contentTableConfig="contentTableConfig"
      :isButtonStyle="true"
      :dataList="staffRankBindList"
    >
      <template #handler="scope">
        <div class="handle-btns">
          <el-button plain size="mini" @click="handleDetailClick(scope.row)">
            详情
          </el-button>
        </div>
      </template>
      <template #userRealname="scope">
        <div class="userRealname">
          <img :src="scope.row.userPhoto" alt />
          <div class="name">{{ scope.row.userRealname }}</div>
        </div>
      </template>
    </page-content>

    <staff-edit-draw
      :isChange="isUpdateStaff"
      v-if="pageDialogVisible"
      @handleResetPasswordClick="handleResetPasswordClick"
      :staffId="currentEditStaffId"
      @changedDrawer="handleStaffEditdDrawer"
      :dialogVisible="pageDialogVisible"
      @handleAssociateClick="handleAssociateClick"
      @handleStaffEditClick="handleStaffEditClick"
    ></staff-edit-draw>
    <reset-password-dialog
      :staffId="currentEditStaffId"
      :dialogVisible="resetPasswordDialogVisible"
      @changeDialogVisible="changeResetPasswordDialogVisible"
    ></reset-password-dialog>
    <distribute-dialog
      :lvid="currentEditLvid"
      :staffId="currentEditStaffId"
      :dialogVisible="distributeDialogVisible"
      @changeDialogVisible="changeDistributeDialogVisible"
    ></distribute-dialog>
    <update-staff-form
      @refreshStaffList="refreshStaffList"
      :staffId="currentEditStaffId"
      @changeDialogVisible="changeUpdateDialogVisible"
      :dialogVisible="updateStaffDialogVisible"
    ></update-staff-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config/index'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import TopContent from './cpns/top-content/top-content'
import Breadcrumb from './cpns/breadcrumb/breadcrumb'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import { UPLOAD_BASE_URL } from '@/common/constant'
import distributeDialog from '@/views/main/staff/base-ui/distribute-dialog/distribute-dialog.vue'
import resetPasswordDialog from '@/views/main/staff/base-ui/reset-password-dialog/reset-password-dialog.vue'
import updateStaffForm from '@/views/main/staff/base-ui/update-staff-form/update-staff-form.vue'
import staffEditDraw from '@/views/main/staff/base-ui/staff-edit-draw/staff-edit-draw.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    currentRnid: {
      type: Number,
      default: -1
    },
    currentRank: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    PageContent,
    PageDraw,
    PageDialog,
    TopContent,
    Breadcrumb,
    distributeDialog,
    resetPasswordDialog,
    updateStaffForm,
    staffEditDraw
  },
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const staffRankBindList = computed(() => store.state.staff.staffRankBindList)

    const initPage = () => {
      store.dispatch('staff/getStaffRankBindListAction', [props.currentRnid, COMPANY_ID])
    }

    emitter.on('refreshCurrentRankStaffPageContent', () => {
      initPage()
    })

    const refreshStaffList = () => {
      initPage()
      isUpdateStaff.value = !isUpdateStaff.value
    }

    const isUpdateStaff = ref(false)
    watch(() => props.currentRnid, () => {
      initPage()
    })

    const currentEditStaffId = ref('')

    const pageDialogVisible = ref(false)
    const handleStaffEditdDrawer = (flag) => {
      pageDialogVisible.value = flag
    }

    const resetPasswordDialogVisible = ref(false)
    const handleResetPasswordClick = () => {
      resetPasswordDialogVisible.value = true
    }

    const handleDetailClick = (item) => {
      currentEditStaffId.value = item.userId
      pageDialogVisible.value = true
    }



    const distributeDialogVisible = ref(false)
    const handleAssociateClick = () => {
      distributeDialogVisible.value = true
    }

    const updateStaffDialogVisible = ref(false)
    const handleStaffEditClick = () => {
      // updateStaffDialogVisible.value = true
      router.push({
        path:'/main/staff/editStaffPage',
        query:{
          staffId:currentEditStaffId.value
        }
      })
    }

    const changeResetPasswordDialogVisible = (flag) => {
      resetPasswordDialogVisible.value = flag
    }

    const changeDistributeDialogVisible = (flag) => {
      distributeDialogVisible.value = flag
    }

    const changeUpdateDialogVisible = (flag) => {
      updateStaffDialogVisible.value = flag
    }

    const currentEditLvid = ref('')
    const handleTableCellClick = (item) => {
      currentEditLvid.value = item.subJobLevel
      currentEditStaffId.value = item.userId
      pageDialogVisible.value = true
    }

    onUnmounted(() => {
      emitter.off('refreshCurrentRankStaffPageContent')
    })

    return {
      currentEditLvid,
      isUpdateStaff,
      handleTableCellClick,
      changeUpdateDialogVisible,
      changeDistributeDialogVisible,
      changeResetPasswordDialogVisible,
      handleStaffEditClick,
      updateStaffDialogVisible,
      handleAssociateClick,
      distributeDialogVisible,
      handleResetPasswordClick,
      resetPasswordDialogVisible,
      handleStaffEditdDrawer,
      pageDialogVisible,
      handleDetailClick,
      contentTableConfig,
      COMPANY_ID,
      staffRankBindList,
      UPLOAD_BASE_URL,
      currentEditStaffId,
      refreshStaffList
    }

  }
})
</script>

<style scoped lang="less">
.staff-list {
  flex: 1;
  margin-right: 20px;
  border-top: 1px solid var(--borderColor);
  overflow: hidden;

  &:deep(.hy-table) {
    margin-left: 22px;

    .el-table thead {
      .el-table__cell {
        text-align: left !important;
      }
    }

    .el-table__body-wrapper {
      .el-table__cell {
        padding: 10px 0 !important;
        text-align: left !important;
      }
    }

    .has-gutter {
      color: rgba(80, 80, 80, 1);
      font-size: 14px;
    }
  }

  .handle-btns {
    display: flex;
    flex-wrap: wrap;
    height: 40px;

    &:deep(.el-button) {
      margin-left: 0 !important;
      margin: 4px !important;
    }
  }

  .userRealname {
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
</style>
