<template>
  <div class="agency-list">
    <div class="titles">
      <slot name="title"></slot>
    </div>
    <div class="el-tree-wrap">
      <el-tree  ref="treeRef"  :data="agencyListInfo" :props="treeProps" default-expand-all @node-click="handleNodeClick"
        class="tree-style">
        <template #default="{ node, data }">
          <span class="custom-tree-node">
            <span v-if="node.childNodes.length > 0">
              <img src="@/assets/img/main/staff/tree.png" alt="">

            </span>

            <span v-else>
              <el-icon>
                <document />
              </el-icon>
            </span>
            <span :title="node.label">{{ node.label }}</span>
            <div v-if="node.childNodes.length > 0" class="item-length">{{ node.childNodes.length }}</div>
          </span>
          <!-- v-if="data.lvId==nodeId"  -->
          <el-dropdown  :style="{paddingLeft:node.childNodes.length > 0?'20px':'14px'}">
            <span class="el-dropdown-link">
              ...
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item><slot :row="node" name="edit"></slot></el-dropdown-item>
                <el-dropdown-item><slot :row="node" name="remove"></slot></el-dropdown-item>
                <el-dropdown-item><slot name="create" :row="node"></slot></el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-tree>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref,onMounted } from "vue";
import emitter from "@/utils/eventbus";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    agencyListInfo: {
      type: Array,
      require: true,
    },
    treeProps: {
      type: Object,
      default() {
        return {
          children: "children",
          label: "label",
        };
      },
    },
  },
  components: {},
  emits: ["handleNodeClick"],
  setup(props, { emit }) {
    const store = useStore();
    const nodeId = ref(null);
    const handleNodeClick = (data, node) => {
      nodeId.value = data.lvId
      // console.log(nodeId.value);
      emit("handleNodeClick", node);
    };


    const treeRef=ref()
    onMounted(()=>{

    })

    return {
      handleNodeClick,
      nodeId,
      treeRef
    };
  },
});
</script>

<style scoped lang="less">
.agency-list {
  font-size: 14px;

  .custom-tree-node {
    display: flex;
    align-items: center;
    // width: 200px;

    span {
      display: flex;
      align-items: center;
    }
  }

  .item-length {
    position: relative;
    left: 12px;
    width: 18px;
    height: 18px;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(56, 56, 56, 1);
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:deep(.el-tree-node) {
    line-height: 30px !important;

    .el-tree-node__content {
      min-height: 30px;
      // display: flex;
      // justify-content: space-between;
    }
  }

  border: 1px solid #f5f5f5;

  .tree-style {
    padding: 16px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &:deep(.el-tree) {
    width: 100%;
  }

  .el-tree-wrap {
    display: flex;
    min-height: 300px;
  }

  .title {
    margin: 2vh 0;
  }
}

.titles {
  display: flex;
  justify-content: space-between;

  .update {
    margin: 2vh 1vh;
    color: #5282ff;
    cursor: pointer;
  }
}
.el-dropdown-link{
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
</style>
