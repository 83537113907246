<template>
  <div class="add-rank-dialog">
    <page-dialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="新增职级"
      :isShowFooter="true" @handleSaveClick="handleSaveClick">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="80px" class="demo-ruleForm"
        label-position="left">
        <el-form-item label="对应岗位:" prop="office">
          <el-select v-model="ruleForm.parentId" class="m-2" placeholder="选择职位" size="large">
            <el-option v-for="item in staffAllRankList" :key="item.lvId" :label="item.levelName" :value="item.lvId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职级名称:" prop="name">
          <el-input placeholder="职级名称" v-model="ruleForm.name" maxlength="10"></el-input>
        </el-form-item>

      </el-form>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const ruleForm = reactive({
      name: '',
      parentId: ''
    })
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleSaveClick = () => {
      ruleForm.name = ruleForm.name.replace(/，/g, ",")
      store.dispatch('staff/addPositionRankAction',
        {
          titleID: ruleForm.parentId,
          companyId: COMPANY_ID,
          subJobLevel: ruleForm.name
        }
      )
    }

    const staffAllRankList = computed(() => store.state.staff.staffAllRankList)

    const initPage = () => {
      store.dispatch('staff/getStaffAllRankListAction', COMPANY_ID)
    }
    initPage()


    emitter.on('refreshAddRankDialogPage', () => {
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshAddRankDialogPage')
    })

    return {
      changeDialogVisible,
      ruleForm,
      handleSaveClick,
      staffAllRankList
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  &:deep(.el-select) {
    width: 100%;
  }
}
</style>




