<template>
  <div class="rank-management">
    <top-content></top-content>
    <div class="content">
      <rank-category
        @handleTreeItemChange="handleTreeItemChange"
        @handleTreeNodeClick="handleTreeNodeClick"
      >
      </rank-category>
      <div class="tabs-wrap">
        <el-tabs v-model="activeName">
          <el-tab-pane label="员工列表" name="a">
            <staff-list
              :currentRank="currentRank"
              :currentRnid="currentRnid"
            ></staff-list>
          </el-tab-pane>
          <el-tab-pane label="关联服务" name="b">
            <rankServeList :currentRank="currentRank"></rankServeList>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import TopContent from './cpns/top-content/top-content'
import RankCategory from './cpns/rank-category/rank-category'
import StaffList from './cpns/staff-list/staff-list'
import emitter from '@/utils/eventbus'
import rankServeList from './cpns/rank-serve-list/rank-serve-list.vue'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    TopContent,
    RankCategory,
    StaffList,
    rankServeList
  },
  setup() {
    const currentRnid = ref('')

    const handleTreeNodeClick = (rnid) => {
      currentRnid.value = rnid
    }

    emitter.on('changeStaffFirstRank', id => {
      currentRnid.value = id
    })

    const currentRank = ref(null)
    const handleTreeItemChange = item => {
      // console.log(item);
      currentRank.value = item
      // console.log(currentRank.value);
    }

    const activeName = ref('a')


    return {
      activeName,
      currentRank,
      handleTreeItemChange,
      breadcrumbList,
      handleTreeNodeClick,
      currentRnid
    }

  }
})
</script>

<style scoped lang="less">
.rank-management {
  box-sizing: border-box;

  .content {
    display: flex;
    justify-content: space-between;
    background-color: white;
    min-height: 750px;

    .tabs-wrap {
      flex: 1;
      padding-left: 20px;
    }
  }
}
</style>
