<template>
  <div class="top-content">
    <el-button @click="handleAddStaffClick" type="primary" v-has="'staff:serve:add'">添加服务</el-button>
    <distribute-dialog :lvid="currentRnid" :dialogVisible="distributeDialogVisible"
      @changeDialogVisible="changeDistributeDialogVisible"></distribute-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import AddStaffDialog from './cpns/add-staff-dialog/add-staff-dialog'
import distributeDialog from '../distribute-dialog/distribute-dialog.vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    currentRnid: {
      type: Number,
      default: -1
    }
  },
  components: {
    AddStaffDialog,
    distributeDialog
  },
  setup(props) {
    const distributeDialogVisible = ref(false)
    const changeDistributeDialogVisible = (flag) => {
      distributeDialogVisible.value = flag
    }
    const handleAddStaffClick = () => {
      let currentRnid = props.currentRnid
      if (currentRnid == -1) {
        ElMessage({
          message: '请在左侧先选择职级！',
          type: 'warning',
        })
        return
      }
      distributeDialogVisible.value = true
    }
    return {
      distributeDialogVisible,
      changeDistributeDialogVisible,
      handleAddStaffClick
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  background-color: white;
  padding: 16px 22px;

}
</style>




