<template>
  <div class="rank-dialog">
    <pageDialog
      :isShowFooter="true"
      width="700px"
      v-if="dialogVisible"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="添加员工"
    >
    <div class="staff-content">
        <div class="right">
          <div class="title">门店列表</div>
          <div class="shop-list">
            <template v-for="(item, index) in shopList" :key="index">
              <div
                @click="shopItemClick(item, index)"
                :class="{ active: shopIndex == index }"
                class="item"
              >
                {{ item.name }}
              </div>
            </template>
          </div>
        </div>
        <div class="left">
          <div class="title">
            选择人员
          </div>

          <el-scrollbar height="400px">
            <el-checkbox-group @change="handleGroupChange" v-model="checkList">
              <template v-for="item in rankList" :key="item">
                <el-checkbox
                  :disabled="item.bindOtherRule"
                  :label="item.id"
                >
                  {{ item.name }}</el-checkbox
                >
              </template>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
      </div>

      <template #footer>
        <div class="save-btn">
          <el-button @click="handleSaveClick" size="small" type="primary">保存</el-button>
        </div>
      </template>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, nextTick, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { getAdminUSerTree } from '@/service/main/staff'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    currentRnid: {
      type: Number,
      default: -1
    },
    jobLevel: {
      type: Number,
      default: -1
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'refreshList', 'checkedChange'],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleSaveClick = () => {
      store.dispatch('staff/saveStaffRankBindAcrion', {
        userList: [...checkList.value],
        currentRnid: props.currentRnid,
        jobLevel: props.jobLevel
      })
      checkList.value = []
      changeDialogVisible(false)
    }

    const rankList = ref([])
    const shopList = ref([])
    const shopIndex = ref(0)
    const disabledList = ref([])

    const checkList = ref([])


    const handleGroupChange = async value => {
      checkList.value=value
      let array = []
      shopList.value.map(item=>{
        let list = item.list||[]
        array=[...array,...list]
      })
    }
    const allCheckList = ref([])

    const initShopList = ref([])


    const updateAllCheckedFlag = () => {
      try {
        let staffList = rankList.value
        allCheckList.value[shopIndex.value<0?0:shopIndex.value]['flag'] = false
        // console.log(disabledList.value);
        // console.log(staffList);
        // console.log(allCheckList.value);
        // console.log(shopIndex.value<0?0:shopIndex.value);
        staffList.forEach(item => {
          let flag = disabledList.value.some(ele => ele == item.userId)
          // console.log(flag);
          if (flag) {
            allCheckList.value[shopIndex.value<0?0:shopIndex.value]['flag'] = true
            throw new Error('End Loop')
          }
        })
      } catch (error) {
        // console.log(error);
      }
    }

    watch(rankList, (n) => {
      updateAllCheckedFlag()
    })

    watch(disabledList, (n) => {
      updateAllCheckedFlag()
    })

    const shopItemClick = async (item, index) => {
      shopIndex.value =  index<0?0:index
      rankList.value = item.list
    }

    const allStaffList = ref([])
    const initPage = async () => {
      let shopRes = await getAdminUSerTree({subJobLevel:props.currentRnid})
      shopList.value = shopRes.data
      rankList.value = shopRes.data[0].list
    }
    initPage()

    return {
      handleSaveClick,
      changeDialogVisible,
      checkList,
      disabledList,
      initShopList,
      allStaffList,
      allCheckList,
      shopItemClick,
      shopIndex,
      shopList,
      handleGroupChange,
      rankList,
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;

  &:deep(.el-scrollbar__view) {
    font-size: 12px;
  }

  &:deep(.el-checkbox__label) {
    font-size: 12px;
  }

  .save {
    &:deep(.el-button) {
      width: 100%;
    }
  }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-dialog) {
    min-width: 350px;

    .el-dialog__body {
      padding-bottom: 10px !important;
    }
  }

  &:deep(.el-dialog__body) {
    position: relative;
    top: -15px;
  }

  .search {
    margin-bottom: 14px;
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-scrollbar) {
    padding: 0 10px;
  }

  .pagination {
    margin-top: 10px;
    text-align: right;
  }

  .shop-list {
    .titls {
      padding: 0 10px;
      display: flex;
      margin-bottom: 20px;
      background-color: rgb(239, 239, 239);
      font-size: 12px;

      div {
        flex: 1;
        line-height: 40px;
      }
    }

    &:deep(.el-checkbox) {
      display: flex;
      align-items: center;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right {
            position: relative;
            left: -8px;
          }

          .left,
          .right,
          .center {
            flex: 1;
          }

          .left {
            display: flex;
          }
        }
      }
    }
  }
}
.rank-dialog {
  .save-btn {
    margin-top: 20px;
  }

  .staff-content {
    display: flex;
    justify-content: space-between;

    .left {
      flex: 1;
    }

    .right {
      flex: 1;

      .shop-list {
        .item {
          padding: 0 10px;
          line-height: 40px;
          cursor: pointer;
        }

        .active {
          color: #ff6600;
          background-color: rgb(246, 247, 251);
        }
      }
    }
  }

  &:deep(.el-dialog__body) {
    padding-top: 15px;
  }

  .title {
    background-color: rgb(239, 239, 239);
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  &:deep(.el-checkbox-group) {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
}
</style>
