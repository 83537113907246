<template>
  <div class="add-rank-dialog">
    <page-dialog
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="关联服务"
      width="660px"
      :isShowFooter="true"
      @handleSaveClick="handleSaveClick"
    >
      <div class="reserve-project-list">
          <div class="reserve-top-form">
              <el-input
                  class="reserve-el-select"
                  @input="handleSearch"
                  size="small"
                  prefix-icon="search"
                  v-model="searchValue"
                  placeholder="输入名称"
              />
          </div>
          <div class="serve_flex">
              <div class="category-list">
                  <template :key="item" v-for="(item, index) in teacherServeList">
                      <div @click="handleTypeClick(item, index)" :class="{ active: optServeId == item.kindId }" class="item">
                          {{ item.kindName }}
                      </div>
                  </template>
              </div>
              <div class="shop-list">
                  <div class="titls">
                      <div style="width: 230px;">服务</div>
                      <div>价格</div>
                      <div>时长</div>
                      <div style="width: 80px;">勾选</div>
                  </div>
                  <el-scrollbar height="240px" width="520px">
                      <div class="serve-list" v-if="teacherServeInfoList&&teacherServeInfoList.length">
                          <template :key="item" v-for="item in teacherServeInfoList">
                              <div class="item">
                                  <div class="left">
                                      <div class="name">{{ item.serveName }}</div>
                                  </div>
                                  <div class="right">
                                      <div class="checkbox">
                                          <div class="salePrice">¥{{ item.price }}</div>
                                          <div>{{ item.serveLength }}分钟</div>
                                      </div>
                                  </div>
                                  <div class="icon">
                                    <el-checkbox :disabled="item.disabled" v-model="item.checked" size="large" />
                                  </div>
                              </div>
                          </template>
                      </div>
                  </el-scrollbar>
              </div>
          </div>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import { useState } from '@/hooks'
import { ElMessage } from 'element-plus'
import { getServeCategoryList,bingJobLevelServe, getAllRankBindServeList } from '@/service/main/staff'
import { getServeList } from '@/service/main/staff'
import { getTeacherRankServeList } from "@/service/main/reserve";

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    lvid: {
      type: Number,
      default: -1
    },
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
      if (flag) {
        initPage()
      }
    }
    const rankBindServeList = ref([])

    const teacherServeList = ref([])
    const teacherServeInfoList = ref([])
    const optServeId = ref('')
    const serveInfo = reactive({
        serveName:'',
        serveId:''
    })
    const searchValue = ref('')
    const handleSearch = value => {
        let list = teacherServeList.value.filter(item=>{return item.kindId==optServeId.value})[0].infos
        let newList = []
        if (value.trim().length > 0) {
            list.forEach(ele => {
            if (ele.serveName.indexOf(value) != -1) {
                newList.push(ele)
            }
            })
        } else {
            newList = list
        }
        teacherServeInfoList.value = newList
    }
    const handleTypeClick = (item, index) => {
        let value = item.kindId;
        optServeId.value = value;
        let list = teacherServeList.value
        if (String(value).length > 0) {
            list = list.filter(ele => ele.kindId == value)
        }
        teacherServeInfoList.value = list[0].infos
    }
    const getServeCatewgory = async () => {
        const res = await getTeacherRankServeList({})
        res.data.map(item=>{
          item.infos.map(items=>{
            items.kindId=item.kindId
            items.disabled = rankBindServeList.value.includes(items.serveId)
            items.checked = rankBindServeList.value.includes(items.serveId)
          })
        })
        teacherServeList.value=res.data
        optServeId.value = teacherServeList.value[0].kindId;
        teacherServeInfoList.value=teacherServeList.value[0].infos
    }
    const optQueryinfo = reactive({
      serveId: "",
      jobLvId: "",
      salePrice: "",
      promPrice: "",
      serveTimeLength: "",
      paraText: "默认规格",
      paraId: 0,
      companyId: COMPANY_ID
    })

    const currentOptServe = reactive({
      id: 0,
      flag: false,
      serveInfo: '',
      paraId: ''
    })

    const handleSaveClick = async() => {
      let checked = []
      teacherServeList.value.map(item=>{
        item.infos.map(items=>{
          if (items.checked) {
            checked.push(items.serveId)
          }
        })
      })
      let res = await bingJobLevelServe({ serveIds:checked,subJobId:optQueryinfo.jobLvId })
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        changeDialogVisible(false)
        emitter.emit('refreshRankBindServeListPage')
      }
    }

    const initPage = async () => {
      let lvid = props.lvid
      if (lvid != -1) {
        optQueryinfo.jobLvId = lvid
        const optRes = await getAllRankBindServeList(lvid)
        let array = optRes.data.list.map(item=>{
          return JSON.stringify(item.serveId)
        })
        rankBindServeList.value = array
      }
      getServeCatewgory()
    }

    return {
      changeDialogVisible,
      currentOptServe,
      rankBindServeList,
      optQueryinfo,
      searchValue,
      handleSearch,
      teacherServeList,
      teacherServeInfoList,
      serveInfo,
      handleTypeClick,
      optServeId,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;

  .reserve-project-list {
      .serve_flex{
          display: flex;
      }
      .active {
          background-color: #fff !important;
          color: rgb(133, 88, 250);
          font-weight: 550;
      }
      .category-list {
          width: 100px;

          .item {
          cursor: pointer;
          text-align: center;
          font-size: 14px;
          line-height: 45px;
          background-color: rgb(239, 239, 239);
          border-bottom: 1px solid rgb(255, 255, 255);
          min-width: 100px;
          cursor: pointer;
          }
      }

      .reserve-top-form {
          display: flex;
          justify-content: space-between;
          background-color: rgb(239, 239, 239);
          padding: 10px 20px;

          .reserve-el-select {
          width: 45%;
          }
      }

      &:deep(.serve-list-wrap) {
          padding-right: 10 !important;
      }

      .empty {
          width: 100%;
          display: flex;
          justify-content: center;
      }
  }
  .shop-list {
    width: 520px;
    text-align: center;
    .serve-list {
      .item {
        display: flex;
        height: 40px;
        align-items: center;
        border-bottom: 1px solid #efefef;
        .icon{
            width: 80px;
            display: flex;
            justify-content: center;
          }

        .left {
        //   width: 330px;
          display: flex;
          height: 40px;
          align-items: center;
          .name{
            width: 230px;
          }
        }

        .right {

          .checkbox {
            display: flex;

            div {
                width: 110px;
            }

            .salePrice {
              color: rgb(255, 105, 74);
            }
          }
        }
      }
    }

    .titls {
      display: flex;
    //   margin-bottom: 20px;

      div {
        width: 110px;
        line-height: 40px;
        background-color: rgb(239, 239, 239);
      }
    }

  }
}
</style>
